import React, { ReactElement, useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import Layout from '../../layouts/Layout/Layout'
import './AboutUs.scss'
import { RecentArticleTypes } from '../../layouts/ArticleLayout/ArticleLayout'
import ABOUT_US_NAVIGATION from '../../data/about-us-navigation.json'
import Seo from '../../components/Seo/Seo'
import loadable from '@loadable/component'
import { LazyLoadImage } from 'react-lazy-load-image-component'

const ArticleLayout = loadable(() =>
  import('../../layouts/ArticleLayout/ArticleLayout'),
)

type AboutUsTypes = {
  title: string
  images: {
    description?: string
    featured?: boolean
    url: string
  }[]
  body: {
    type: string
    value: string
  }[]
}

type AboutUsPropTypes = {
  data: {
    aboutUsData: AboutUsTypes
    recentArticles: {
      nodes: RecentArticleTypes[]
    }
  }
}

const AboutUs = ({
  data: {
    aboutUsData,
    recentArticles: { nodes: recentArticles },
  },
}: AboutUsPropTypes): ReactElement => {
  const [bannerImage] = useState(aboutUsData.images[0]?.url || '')
  const [title] = useState(aboutUsData.title || 'About Us')

  return (
    <Layout>
      <Seo
        title={title}
        ogMetaData={{
          title: title,
          description:
            'More than just the basics, Avida provides lifestyle essentials: quality and intuitive designs that are built to enrich people’s lives. Believing that inspired living means happier living, it is the company’s vision to inspire and affect everyday moments, fostering a lifestyle that inspires endless possibilities.',
          image: bannerImage,
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/about-us/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, about us, brand history',
        }}
      />
      <ArticleLayout
        title="About Us"
        asideNavItems={ABOUT_US_NAVIGATION}
        breadcrumbs={[
          {
            label: title,
          },
        ]}
        recentArticles={recentArticles}
        contentClassName="about-us"
      >
        <>
          {!!bannerImage && (
            <LazyLoadImage
              src={bannerImage}
              alt="banner image"
              className="about-us-banner"
            />
          )}
          <div className="about-us-content-container">
            <h2 className="about-us-title">{title}</h2>
            <div className="about-us-content">
              {!!aboutUsData.body &&
                aboutUsData.body.map((content) => (
                  <p key={content.value}>{content.value}</p>
                ))}
            </div>
          </div>
        </>
      </ArticleLayout>
    </Layout>
  )
}

export default AboutUs

export const pageQuery = graphql`
  query {
    aboutUsData: aboutUs {
      ...AboutUsFields
    }
    recentArticles: allNewsAndEvents(limit: 3) {
      nodes {
        ...NewsAndEventsCardFields
      }
    }
  }
`
